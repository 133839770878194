import React from 'react';
import * as styles from './page.module.scss';
import Layout from "../page-components/layout/layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import IndexLatestRecords from "../templates-parts/indexLatestRecords"
import IndexFurtherLinks from "../templates-parts/indexFurtherLinks"
import KontaktPart from "../templates-parts/kontaktPart"

const PageTemplate = ( { data } ) => {

  const node = data.allWpPage.nodes[0];
  const isIndex = node.slug === "startseite" || node.slug === "/" || node.slug === "";
  const isKontakt = node.slug === "kontakt";


  return <Layout>

    <div className={styles.teaserWrapper}>

      <GatsbyImage alt={"Teaser"}
                   className={styles.teaser}
                   image={node.acf_page.teaser.localFile.childImageSharp.gatsbyImageData} />

      <div className={styles.teaserGradient} />



      {isIndex &&
        <div className={styles.teaserContent}>
          <h1 className={"text-white d-block " + styles.welcome}>Willkommen</h1>
          <h3 className="text-white">in der neuen Welt der Kommunikation!</h3>
        </div>
      }

    </div>



    {
      isIndex &&
      <div className={"boxBlue py-4 mb-2 text-center " + styles.access}>
        Für den Bürger und den Gast.
      </div>
    }

    {!isIndex &&
      <div className={"boxBlue py-4 mb-2 text-center"}>
        <h1>{node.title}</h1>
      </div>
    }



    <div className="container py-5">
      <div className="row mb-4">
        <div className="col-12">


          <div dangerouslySetInnerHTML={
            {
              __html: node.content
            }
          } />

        </div>
      </div>

      { isIndex && <IndexLatestRecords records={node.acf_page.currentRecords} /> }
      { isKontakt && <KontaktPart /> }
    </div>

    <div className={styles.bgGray}>
      <div className={"container py-4"}>
        <div className="row">
          <div className="col-12">
            { isIndex && <IndexFurtherLinks node={node} /> }
          </div>
        </div>
      </div>
    </div>


  </Layout>;

};

export default PageTemplate;


export const query = graphql`query PageDetail($id: String!) {
    allWpPage(filter: {id: {eq: $id}}) {
      nodes {
        content
        id
        slug
        title
        acf_page {
          teaser {
            id
            localFile {
              url
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          
          currentRecords {
            ... on WpRecord {
              databaseId
              id
              slug
              title
              acf {
                stadt {
                  ... on WpStadt {
                    id
                    title
                  }
                }
                teaser {
                  localFile {
                    url
                    childImageSharp {
                      gatsbyImageData(aspectRatio: 2)
                    }
                  }
              }
              }
            }
          }
          
          furtherlinks {
            title
            url
            description
            teaser {
              id
              localFile {
              url
              childImageSharp {
                gatsbyImageData(aspectRatio: 2)
              }
            }
            }
          }
        }
      }
    }
  }`;
