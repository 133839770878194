import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "../templates/ort.module.scss"
import RecordCard from "../page-components/recordCard"

const IndexLatestRecords = ( { records }) => {


  return <div className="row">
      <div className="col-12">
        <div className="boxBlue text-center">
          Aktuelle interregionale 3D-Räume
        </div>
      </div>


    {
      records?.map(
          r => <div key={r.id} className={"col-lg-4 mt-3 " + styles.record}>
            <RecordCard record={r} />
          </div>
        )
    }

  </div>

};

export default IndexLatestRecords;
