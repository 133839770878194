import React from 'react';
import * as styles from './kontaktPart.module.scss';

const KontaktPart = () => {

  return <div className="row">
      <div className="col-12">

        <form
          className={styles.form}
          action="https://formspree.io/f/mjvlvjqb"
          method="POST"
        >

          <label>
            Firma/Kontaktperson*
            <input type={"text"} name={"firma_kontaktperson"} />
          </label>

          <label>
            E-Mail:
            <input type="email" name="_replyto" />
          </label>

          <label>
            Telefonnummer:
            <input type={"tel"} name="telefon" />
          </label>

          <label>
            Nachricht
            <textarea name="message" rows={10} />
          </label>

          <button type="submit">Senden</button>
        </form>

      </div>
    </div>;

};

export default KontaktPart;
