import React from 'react';
import * as styles from './indexFurtherLinks.module.scss';

import { GatsbyImage } from "gatsby-plugin-image"
import EyeSVG from "../../icons/fontawesome/light/eye.svg"

const IndexFurtherLinks = ( { node } ) => {

  const elements = node.acf_page.furtherlinks;

  console.log('*** elements ', elements);

  return <div className={"row mt-4 "}>

    {
      elements.map(
        (e) => {
          const description = e.description;
          console.log('*** description ', description);

          return <div className={"col-lg-4 "}>
            <a href={e.url} target={"_blank"}>
              <div className={styles.cardWrapper + " c-pointer"}>

                <div className={styles.content}>
                  <div className={"boxBlue mb-2"}>{e.title}</div>
                  <GatsbyImage alt={"Image"} image={e.teaser.localFile.childImageSharp.gatsbyImageData} />
                </div>

                <div
                  className={styles.cardGradient + " text-white text-decoration-none d-flex flex-column align-items-center my-0" +
                    " justify-items-center justify-content-center text-center text-white"}>
                  <div className="d-flex align-items-center">
                    <EyeSVG className={"svgFill me-2"} />
                    Ansehen
                  </div>
                </div>

              </div>

            </a>

            <div className={styles.description + " mt-2 text-center"}
                 dangerouslySetInnerHTML={{ __html: description }} />

          </div>
        }
      )
    }

  </div>

};

export default IndexFurtherLinks;
