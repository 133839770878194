import { Link } from "gatsby"
import * as styles from './recordCard.module.scss';
import React from "react"

import EyeSVG from "../../icons/fontawesome/light/eye.svg";
import { GatsbyImage } from "gatsby-plugin-image"

const RecordCard = ( { record, showStadt = false } ) => {

  return <div className={styles.cardWrapper}>


    { showStadt && record.acf.stadt?.length > 0 && <p className={"boxBlue mb-0 text-center " + styles.ort}>{record.acf.stadt[0].title}</p> }
    <div className={styles.content}>
      <Link to={"/aufnahme/" + record.slug}>
        <GatsbyImage alt={"Aufnahme " + record.title} image={record.acf.teaser?.localFile?.childImageSharp?.gatsbyImageData} />
      </Link>
    </div>


    <Link to={"/aufnahme/" + record.slug}
          className={styles.cardWrapperGradient + " text-white text-decoration-none d-flex flex-column align-items-center my-0" +
          " justify-items-center justify-content-center text-center text-white"}>
      <div className="d-flex align-items-center">
        <EyeSVG className={"svgFill me-2"} />
        Ansehen
      </div>
    </Link>


    <div className={styles.contentTitle}>
      <Link to={"/aufnahme/" + record.slug} className={"primary-dark text-decoration-none"}>
        <p className={styles.title + " mt-0 text-center primary-dark boxBlue"}>
          { record.title }
        </p>
      </Link>
    </div>

  </div>

};

export default RecordCard;
